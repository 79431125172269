#passwordMessage {
  color: orangered;
  margin: 10px 0 5px;
  display: none;
}

#passwordMessage.success {
  color: limegreen;
}

#passwordMessage.show {
  display: block;
}

#download-image {
  display: none;
}

#download-image.show {
  display: grid;
}
