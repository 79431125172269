.project-cover-full {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10%;
}

.project-cover-full.wide {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.project-cover-full__background {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #000000;
  transition: background-color 1s;
}

.project-cover-full.show .project-cover-full__background {
  background-color: transparent;
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
  opacity: 0;
  transition: opacity 1s;
}

#overlay.show {
  opacity: 1;
}

@media (min-width: 800px) {
  .project-cover-full {
    height: 100vh;
    height: 100dvh;
    padding-top: 0;
  }
}
