.scroll-helper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  /* font-size: 12px; */
  font-size: 11px;
  /* font-size: 12px; */
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 2s ease 0s, transform 1s ease;
  opacity: 1;
  /* transform: translateY(0); */
}

.scroll-helper.hide {
  opacity: 0;
  /* transform: translateY(-20px); */
}

.scroll-helper__text {
  position: absolute;
  height: 10px;
  /* width: 10px; */
  /* background: #ffffff; */
  display: block;
  /* border-radius: 50%; */
  /* border: 1px solid #ffffff; */
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translateY(-40px); */
  transform: translateY(-25px);
  width: 300px;
}

.scroll-helper__text span {
  position: absolute;
  width: fit-content;
  animation: none;
  transform: none;
  opacity: 1;
}

.animate .scroll-helper__text span {
  opacity: 0;
  transform: translateY(-15px);
}

.animate .scroll-helper__text-one {
  animation: scroll-text 8s ease infinite;
}

.animate .scroll-helper__text-two {
  animation: scroll-text 8s ease 4s infinite;
}

@keyframes scroll-text {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  15% {
    opacity: 1;
    transform: translateY(0);
  }
  45% {
    opacity: 1;
    transform: translateY(0);
  }
  60% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 0;
  }
}

/* .animate .scroll-helper__text {
  animation: scroll-helper 3s ease infinite;
} */

.scroll-helper__text img {
  height: 40px;
  width: 40px;
}

@keyframes scroll-helper {
  0% {
    transform: translateY(-40px);
  }
  60% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(-40px);
  }
}
