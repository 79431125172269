.intro {
  height: 100vh;
  height: 100dvh;
  width: 100%;
  position: relative; /* for logo */
  display: flex;
  align-items: center;
  padding: 0;
  opacity: 1;
  z-index: 999;
  top: 0;
  left: 0;
  transition: height 3s ease-in-out 1.5s, padding 2s ease-in-out 1.5s, opacity 2s ease-in-out 1.5s;
  pointer-events: none;
}

.intro.ended {
  opacity: 0;
  pointer-events: none;
}

.intro.hide {
  opacity: 0;
  background-color: transparent;
}

.intro__logo {
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.intro__logo img {
  height: 100%;
  width: auto;
}

.intro__text {
  display: flex;
  flex-direction: column;
  padding: 5%;
  transform: translate(0, 0);
  transition: transform 2s ease-in-out 2s;
}

.intro__text p {
  margin: 0;
  color: white;
  line-height: 0.85;
  font-size: max(min(18.5vw, 14rem), 3.5rem);
  letter-spacing: -0.2rem;
  word-spacing: 0.2rem;
  text-transform: uppercase;
  font-weight: 300;
}

.intro__text_4_f_3 {
  letter-spacing: 0rem;
}
