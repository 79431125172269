.project-nav {
  padding: 0 5% 100px;
  padding: 0 10% 60px;
}

.project-nav__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px 0;
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
  height: 40px;
}

.project-nav__links .tag-link h3 {
  /* font-size: 15px; */
  font-size: 13.5px;
  /* font-weight: 400; */
  font-weight: 300;
}

.project-nav.show .project-nav__links {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 800px) {
  .project-nav {
    padding: 0 20px 20px;
    flex-direction: row;
  }

  .project-nav__links {
    gap: 30px 0;
    flex-direction: row;
  }

  .project-nav__link.close {
    width: auto;
    order: initial;
  }
}

@media (min-width: 1920px) {
  .project-nav__link .tag-link h3 {
    font-size: 16px;
  }
}
