body.not-found-page footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.page.not-found-page {
  flex-grow: 1;
  display: flex;
  color: #ffffff;
  color: var(--yellow);
}

.page.not-found-page .page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

@keyframes fade404 {
  from {
    opacity: 0;
    transform: translate(0, 30px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.page.not-found-page h1 {
  font-size: 100px;
  margin: 0;
  display: flex;
  gap: 0 4px;
  -webkit-text-stroke: 1px var(--yellow);
  text-stroke: 1px var(--yellow);
  color: transparent;
  animation: fade404 1s ease-in-out 1 forwards;
}

.page.not-found-page img {
  width: 90px;
}

.page.not-found-page p {
  font-size: 20px;
}
