.footer__tags {
  display: flex;
  padding: 30px 5% 30px;
  justify-content: space-between;
  color: #ffffff;
  opacity: 0;
  transform: translate(0, -15px);
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.footer.show .footer__tags {
  opacity: 1;
  transform: translate(0, 0);
}

.footer .tag h3 {
  font-size: 8px;
}

@media (min-width: 800px) {
  .footer__tags {
    padding: 30px 20px;
  }
}
