.project-images {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px 0;
}

.project-images__image-container {
  width: 100%;
}

.project-images__image-holder {
  width: 100%;
  opacity: 0;
  transform: translate(0, 30px);
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
  position: relative;
}

.project-images__image-container.show .project-images__image-holder {
  opacity: 1;
  transform: translate(0, 0);
}

.project-images__image-holder::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  z-index: 1;
  pointer-events: none;
}

.project-images__image-holder.loading::before {
  opacity: 0;
}

.project-images__image {
  width: 100%;
  transition: opacity 1.5s ease-in-out;
  opacity: 1;
  z-index: 10;
  position: relative;
}

.project-images__image.loading {
  opacity: 0;
}

@media (min-width: 800px) {
  .project-images {
    gap: 100px 0;
  }
}
