.projects > div {
  width: 100%;
}

.projects .project {
  margin-bottom: min(5vw, 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-inner {
  transform: scale(0.8) translateY(40px);
  position: relative;
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease;
}

.show .project-inner {
  transform: scale(1) translateY(0);
  opacity: 1;
  transition: opacity 1s ease-in-out, transform 1s ease;
}

.project__cover-img {
  position: relative;
  opacity: 1;
  width: 100%;
  /* transform: scale(0.8) translateY(0); */
  transform: scale(1) translateY(0);
  transition: opacity 1s ease-in-out, transform 1s ease;
  /* cursor: pointer; */
  cursor: url("../../assets/images/cursor.svg") 5 0, pointer;
  /* cursor: none; */
}

@keyframes projectCoverFade {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(40px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

/* .filtered .project__cover-img {
  animation: projectCoverFade 1s ease-in-out 2s 1;
} */

/* .project__cover-img {
  transition-delay: 1s;
} */

.show .project__cover-img {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.show .project__cover-img:hover {
  transform: scale(1.03) translateY(0);
}

@media (min-width: 800px) {
  .projects.filtered > div {
    /* width: 47.5%; */
    max-width: 400px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-grow: 1; */
  }

  .filter-motion .projects.filtered > div {
    max-width: 800px;
    width: 47.5%;
  }

  .projects .project {
    display: block;
  }

  .projects.filtered .project {
    /* max-width: 400px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .projects.filtered .project__cover-img {
    max-width: 100%;
    height: fit-content;
  }

  /* .from-nav  */
  .projects.filtered .project.animate {
    opacity: 0;
    transform: scale(0.8) translateY(40px);
    animation: projectCoverFade 1s ease-in-out 1s 1 forwards;
  }
}

/* @media (min-width: 1280px) {
  .projects.filtered > div {
    max-width: 400px;
    width: 30%;
  }

  .filter-motion .projects.filtered > div {
    max-width: 1000px;
    width: 47.5%;
  }
} */
