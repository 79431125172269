.temporary-page {
  color: white;
  /* cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="40" height="40"><circle cx="10" cy="10" r="6" fill="%23bcfc45" /></svg>')
      10 10,
    auto;
  cursor: url("../../assets/Cursors/MELT_Live.png") 50 50, auto; */
  cursor: none;
}

.temporary-page .mq {
  padding: 0 5vw;
}

.temporary-page #cursor {
  position: fixed;
  z-index: 1;
  /* left: -50px; */
  /* top: -50px; */
  pointer-events: none;

  width: 100px;
  height: 100px;
  left: -50px;
  top: -50px;
  /* background-color: #bcfc45; */
  border-radius: 50%;
  /* transform: translate(50%, 50%); */
  animation: tempFadeIn 1s linear 1;
}
.temporary-page #cursor img {
  position: absolute;
  top: 0;
}
.temporary-page #cursor img:nth-of-type(1) {
  animation: tempRotate 12s ease-in-out infinite reverse;
}

@keyframes tempRotate {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes tempFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
