.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  max-width: 3480px;
  margin-left: auto;
  margin-right: auto;
}

.projects.loading {
  min-height: 5000px;
}

@media (min-width: 800px) {
  .projects {
    align-items: normal;
  }

  .projects.filtered {
    flex-direction: row;
    gap: 0 min(5%, 80px);
    width: 100%;
    flex-wrap: wrap;
    padding: 0 min(5%, 80px);
  }
}

/* @media (min-width: 1280px) {
  .projects.filtered {
    gap: 0 min(5%, 80px);
  }
} */
