* {
  box-sizing: border-box;
}

html {
  background: #000000;

  --yellow: #bcfc45;
  --text-color: #ffffff;
  --transition-fadescroll: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
  --screen: 800px;
  --font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --cursor-scale: 1; /* of 80px */
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
  transition: background-color 1s ease;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  cursor: default;
}

a:hover {
  cursor: url("assets/images/cursor.svg") 5 0, pointer;
  /* cursor: none; */
}
