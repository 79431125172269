/* @import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
.App {
  text-align: center;
}
hr {
  height: 1px;
  background-color: #ffffff;
  border: none;
  opacity: 1;
}

a {
  text-decoration: none;
}

.page a {
  color: #ffffff;
  transition: color 0.5s ease;
}

.about-page .page a {
  color: #000000;
}

.page a:hover {
  color: var(--yellow);
}

.about-page a:hover {
  color: #000000;
}

.page {
  transition: color 1s ease;
}

.about-page .page {
  background: #ffffff;
  color: #000000;
}

h1,
.page h1 {
  font-weight: 400;
  margin-top: 0;
  font-size: 13.5px;
  /* font-size: 15px; */
  color: #777777;
  letter-spacing: -0.005em;
  /* text-transform: uppercase; */
}

/* .about-page .page h1 {
  color: #333333;
} */

.page h2 {
  font-size: 1.2rem;
}

.page h3,
.tag h3 {
  font-size: 10px;
  letter-spacing: 0.04em;
  line-height: 1.1;
  font-weight: 400;
  margin: 0;
}

/* .subtitle-text, */
.tag h3 {
  text-transform: uppercase;
}

.nav-menu .tag h3,
.nav-menu .tag span,
.nav-menu .nav-menu__info p,
.project-text .subtitle-text {
  /* font-size: 15px; */
  font-size: 13.5px;
  text-transform: none;
  font-weight: 300;
}

.about-page .col.info h1,
.about-page .col.nav .tag h3,
.about-page .col.info .tag h3 {
  font-size: 13.5px;
  text-transform: none;
  letter-spacing: -0.0025em;
  font-weight: 400;
}

.about-page .col.info h1,
.about-page .col.info .tag h3,
.about-page .col.info .tag-row span,
.about-page .col.nav #about-link-backtowork h3 {
  font-size: 30px;
}

.about-page .col.info h1,
.about-page .col.info .tag-block__title {
  margin-bottom: 5px;
}

.tag-container {
  display: flex;
  flex-direction: column;
  gap: 3px 0;
}

.page .jumbo-text {
  font-size: 22px;
  /* font-weight: 400; */
  /* font-weight: 350; */
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.005em;
}

.project-text .tag h3,
.project-text .tag-block__tags.tag-row span,
.about-page #about-backtowork-text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.0025em;
  line-height: 1.1;
}

.about-page #about-backtowork-text {
  display: none;
}

.about-page .description-text .tag h3,
.about-page .description-text .tag-block__tags.tag-row span,
.about-page .jumbo-text {
  font-size: 30px;
  font-weight: 400;
}

.about-page .description-text .tag-block__tags.tag-row span {
  font-weight: 100;
}

.about-page .description-text .tag h3 {
  color: #777777;
  transition: color 0.5s ease;
}

.about-page .description-text .tag h3:hover {
  color: #000000;
}

.about-page .description-text .tag-block {
  margin-bottom: 0;
}

.about-page .description-text .tag h3,
.about-page .description-text .tag-block__tags.tag-row span {
  text-transform: none;
  line-height: 1;
  letter-spacing: -0.01em;
  margin-bottom: 1.25em;
}

.project-text .tag h3,
.about-page #about-backtowork-text {
  text-transform: none;
}

.about-page #about-backtowork-text {
  font-weight: 300;
}

.project-text .tag h3 {
  font-weight: 100;
}

.page .jumbo-text p {
  margin: 0 0 1.25em;
}

.page .jumbo-text a {
  position: relative;
}

.page .jumbo-text h1 {
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 1em;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.page .jumbo-text h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 1.5em;
  text-transform: uppercase;
}

.page .jumbo-text h3,
.about-page .description-text .tag-block h1 {
  /* font-weight: 500; */
  font-weight: 400;
  margin: 0 0 2em;
  /* font-size: 13.5px; */
  /* font-size: 15px; */
  font-size: 13.5px;
  /* text-transform: uppercase; */
  text-transform: none;
  letter-spacing: 0;
  letter-spacing: -0.005em;
}

.page .jumbo-text a:after {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 3px;
  background: var(--text-color);
  left: 0;
  transform: scaleX(1);
  transition: transform 0.3s ease;
}

.page .jumbo-text a:hover:after {
  transform: scaleX(0);
}

.title-text {
  margin-bottom: 5px;
}

.project-text .title-text {
  margin-bottom: 1em;
}

@media (min-width: 800px) {
  .page .jumbo-text {
    /* .project-text .tag h3,
  .project-text .tag-block__tags.tag-row span { */
    font-size: 1.5rem; /* fallback */
    /* font-size: min(max(1.5rem, 2.75vw), 38px); */
    /* font-size: min(max(1.5rem, 2.75vw), 36px); */
    font-size: min(max(1.5rem, 2.75vw), 32px);
    letter-spacing: -0.005em;
    line-height: 1;
  }

  .project-text .tag h3,
  .project-text .tag-block__tags.tag-row span,
  .about-page #about-backtowork-text {
    font-size: min(max(18px, 2vw), 24px);
    /* letter-spacing: -0.02em; */
    line-height: 1.1;
  }

  .about-page #about-backtowork-text {
    font-weight: 300;
  }

  .project-text .tag h3,
  .project-text .tag-block__tags.tag-row span {
    font-weight: 100;
  }

  .about-page #about-backtowork-text {
    font-weight: 400;
  }

  .about-page .description-text .tag h3,
  .about-page .description-text .tag-block__tags.tag-row span,
  .about-page .jumbo-text,
  .about-page .col.info h1,
  .about-page .col.info .tag h3,
  .about-page .col.info .tag-row span,
  .about-page .col.nav #about-link-backtowork h3 {
    /* font-size: min(max(1.5rem, 3vw), 60px); */
    /* font-size: min(5vw, 80px); */
    font-size: min(4.25vw, 66px);
    letter-spacing: -0.02em;
  }

  .page .jumbo-text h1 {
    /* font-size: min(max(1.5rem, 2.75vw), 38px); */
    font-size: min(max(1.5rem, 2.75vw), 36px);
    font-weight: 450;
    margin: 0 0 1em;
    letter-spacing: -0.02em;
  }

  .page .jumbo-text h2 {
    font-size: 1.25rem; /* fallback */
    font-size: min(max(1.25rem, 2.5vw), 28px);
  }
}

@media (min-width: 960px) {
  .about-page .col.info h1,
  .about-page .col.info .tag h3,
  .about-page .col.info .tag-row span,
  .about-page .col.nav #about-link-backtowork h3 {
    font-size: 13.5px;
    letter-spacing: -0.0025em;
  }

  .about-page #about-backtowork-text {
    display: block;
  }
}

@media (min-width: 1280px) {
  .project-text .tag h3,
  .project-text .tag-block__tags.tag-row span,
  .about-page #about-backtowork-text {
    /* letter-spacing: -0.02em; */
    letter-spacing: -0.0075em;
  }
}

@media (min-width: 1920px) {
  .page .jumbo-text {
    /* font-size: min(max(1.25rem, 2vw), 44px); */
    /* font-size: min(max(1.25rem, 2vw), 42px); */
    font-size: min(max(1.25rem, 2vw), 38px);
  }

  .project-text .tag h3,
  .project-text .tag-block__tags.tag-row span,
  .about-page #about-backtowork-text {
    font-size: min(max(1.25rem, 2vw), 30px);
  }

  .about-page .description-text .tag h3,
  .about-page .description-text .tag-block__tags.tag-row span,
  .about-page .jumbo-text {
    /* font-size: 80px; */
    font-size: 72px;
  }

  .page h1,
  .nav-menu h1,
  .nav-menu .tag h3,
  .nav-menu .tag span,
  .nav-menu .nav-menu__info p {
    font-size: 16px;
  }

  .page h3,
  .tag h3 {
    font-size: 11.5px;
  }

  .about-page .col.nav .tag h3,
  .about-page .col.info .tag h3,
  .about-page .col.info .tag-block__title h1,
  .about-page .col.nav #about-link-backtowork h3,
  .about-page .col.info .tag-row span {
    font-size: 16px;
    /* letter-spacing: -0.005em; */
  }

  .page .jumbo-text h3,
  .about-page .description-text .tag-block h1 {
    font-size: 16px;
  }
}
