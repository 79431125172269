.cursor-container {
  position: fixed;
  z-index: 999;
  pointer-events: none;
  left: 0;
  top: 0;
  display: none;
}

.cursor {
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  opacity: 1;
  /* background-color: var(--cursor-color); */
}

/* .home-page .cursor.link {
  background-color: var(--yellow);
} */

/* .home-page .cursor.project-click {
  opacity: 1;
  transform: translate(-50%, -50%) scale(var(--cursor-scale));
} */

/* .cursor.project {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
} */

/* .cursor.link {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
} */

#cursor-unofficial {
  width: 100px;
  height: 100px;
  pointer-events: none;
  position: relative;
  transition: opacity 0.2s ease-in-out, fill 0.2s ease-in-out;
  animation: rotate 16s linear infinite forwards;
  opacity: 0;
}

.project-page .cursor {
  background-color: transparent !important;
}

.project-page .cursor.video {
  opacity: 1;
  transform: translate(-50%, -50%) scale(var(--cursor-scale));
  /* transition: none; */
}

/* .project-page .cursor.link {
  background-color: var(--yellow) !important;
} */

#cursor-video-play,
#cursor-video-pause {
  width: 70px;
  height: 70px;
  pointer-events: none;
  position: absolute;
  transform: translate(-25px, -25px);
  top: 0;
  left: 0;
  /* display: none; */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  fill: var(--cursor-color);
}

.project-page .cursor.video #cursor-video-play {
  /* display: block; */
  opacity: 1;
}
.project-page .cursor.video.playing #cursor-video-play {
  /* display: none; */
  opacity: 0;
}
.project-page .cursor.video.playing #cursor-video-pause {
  /* display: block; */
  opacity: 1;
}

@media screen and (min-width: 800px) {
  .cursor-container {
    display: block;
  }
}
