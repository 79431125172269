.tag-block {
  margin-bottom: 30px;
}

.tag-block__title {
  margin-bottom: 1rem;
  margin-bottom: 0.75rem;
}

.tag-block__title__underline {
  display: flex;
  background-color: #ffffff;
  width: 100%;
  margin-top: -5px;
  display: none;
}

.about-page .tag-block__title__underline {
  background-color: #ffffff;
  display: none;
}

.tag-block__title__underline {
  height: 1px;
}

.tag-block__tags {
  display: flex;
  flex-direction: column;
  gap: 5px 0;
}

.tag-links .tag-block__tags {
  /* gap: 8px 0; */
  gap: 5px 0;
}

.tag-block__tags h3 {
  text-transform: uppercase;
}

.about-page .description-text .tag-block__tags.tag-row {
  margin-bottom: 1.25em;
}

.tag-block__tags.tag-row span .tag {
  display: inline;
}

.tag-block__tags.tag-row span .tag h3 {
  display: inline;
  margin: 0;
}

@media (min-width: 800px) {
  .tag-block {
    margin-bottom: 40px;
  }

  .about-page .tag-block {
    margin-bottom: 30px;
  }

  .tag-block__title__underline {
    display: none;
  }
}

@media (min-width: 1920px) {
  .about-page .tag-block {
    margin-bottom: 35px;
  }
}
