#background {
  width: 0px;
  height: 0px;
  pointer-events: none;
  position: fixed;
  top: -1px;
  left: -1px;
  z-index: -9999;
}

#background-container {
  width: 100%;
  height: 100vh;
  z-index: -1;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
}

.about-page #background-container {
  width: 60px;
  height: 60px;
  z-index: 997;
  border-radius: 50%;
  overflow: hidden;
  left: -30px;
  top: -30px;
}

#background-container div,
#background-container canvas {
  pointer-events: none !important;
}

.project-page #background-container {
  position: absolute;
}

#background div {
  background-color: #000000;
  transition: background-color 1s ease 0.1s;
}
