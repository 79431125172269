#layout {
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  transition: height 1s, background-color 0.5s ease;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.description-text {
  display: flex;
  flex-direction: column;
}

.page-container {
  display: flex;
  flex-direction: column;
}

.page-container.loading {
  min-height: 101vh;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.col {
  padding: 0 5%;
  height: fit-content;
}

.col-1 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: fit-content;
}

.col-2 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: fit-content;
}

@media (min-width: 800px) {
  .home-page #layout {
    min-height: 101dvh;
  }

  .home-page.filtered #layout {
    min-height: 190dvh;
  }
}

@media (min-width: 960px) {
  .row {
    flex-direction: row;
    gap: 0 8%;
    padding: 0 20px;
  }

  .col {
    padding: 0 0 0 8%;
  }

  .project-page .col {
    padding: 0 0 0 3%;
  }

  .col-3 .col {
    max-width: 400px;
  }

  .col-1.sticky {
    position: sticky;
    top: 20vh;
    gap: 5px 0;
  }

  .col.full {
    padding: 0;
  }

  .col.primary {
    width: 70%;
    padding: 0;
    /* max-width: 1000px; */
    max-width: none;
  }

  .col.primary.col-tag {
    width: 50%;
  }

  .secondary .col.primary {
    width: 85%;
    max-width: none;
  }

  .col.what {
    order: 2;
  }

  .col.links {
    order: 1;
  }
}

@media (min-width: 1440px) {
  .row {
    gap: 0 4%;
  }

  .col-3 .col {
    width: 20%;
  }

  .col-1 {
    display: flex;
    flex-grow: 1;
    /* flex-direction: row; */
  }

  .col-1 .col {
    width: 100%;
  }

  .col-1.sticky .col.links {
    position: sticky;
    top: 100px;
  }

  .col.what {
    order: 1;
  }

  .col.links {
    order: 2;
  }

  .col.primary {
    /* width: 60%; */
    width: 70%;
  }

  .secondary .col.primary {
    width: 70%;
  }
}

@media (min-width: 1920px) {
  .col-3 .col {
    width: 50%;
  }

  .col.primary {
    width: 70%;
    max-width: 1440px;
  }

  .secondary .col.primary {
    width: 70%;
    max-width: 1440px;
  }
}
