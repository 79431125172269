.tag-link {
  width: fit-content;
  height: fit-content;
  position: relative;
  margin: 0 0 0.35rem;
}

.nav-menu .tag-link {
  margin-bottom: 0;
}

.nav-bar .tag-link,
.tag-link-nav {
  margin: 1rem 0 0.3rem;
}

.tag-block .tag-link-nav {
  margin: 0 0 0.35rem;
}

.tag-links .tag-link {
  margin-bottom: 0;
}

.nav-bar .tag-link h3,
.tag-link-nav h3,
.nav-menu .tag-link h3 {
  text-transform: uppercase;
  color: #ffffff;
  transition: font-variation-settings 0.3s ease, color 0.5s ease;
}

.nav-bar .tag-link:hover h3,
.tag-link-nav:hover h3,
/* .about-page .tag-link h3, */
.nav-menu .tag-link:hover h3 {
  color: var(--yellow);
}

/* .about-page .tag-link:hover h3 {
  color: #ffffff;
} */

.tag-links .tag-block__tags h3 {
  font-weight: 600;
  font-size: 12px;
}

.about-page .nav-bar .tag-link h3,
.about-page .tag-link-nav h3,
.about-page .page .tag-link-nav h3 {
  color: #000000;
}

.tag-link-underline {
  position: relative;
  bottom: 6px;
  width: 100%;
  height: 1px;
  transition: transform 0.3s ease;
  transform: scaleX(0);
  display: none;
}

.tag-link-underline.hover,
.tag-block .tag-link-nav .tag-link-underline.hover {
  transform: scaleX(1);
}

.nav-bar .tag-link .tag-link-underline hr,
.tag-link-nav .tag-link-underline hr {
  background: #ffffff;
}

.about-page .nav-bar .tag-link .tag-link-underline hr,
.about-page .tag-link-nav .tag-link-underline hr {
  background: #000000;
}

.about-page .page .tag-link-nav .tag-link-underline hr {
  background: #000000;
}

@media (min-width: 1920px) {
  .tag-links .tag-block__tags h3 {
    font-size: 13px;
  }
}
