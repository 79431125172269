.other-page .page-container {
  height: 100vh;
  height: 100dvh;
}

.embed-page #layout {
  background-color: #303030;
}

.form__password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.form__password-toggle {
  position: absolute;
  right: 0;
  cursor: pointer;
  font-size: 16px;
}

.form__password-toggle img {
  width: 50px;
  height: 50px;
}

.embed {
  width: 100%;
  height: 100%;
  border: none;
  /* margin-top: 75px; */
  margin-top: 40px;
}
