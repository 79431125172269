.admin-page .page {
  height: 100vh;
  display: flex;
  background: #000000;
  transition: background-color 1s;
}

.admin-page.logged-in .page {
  background: #1b1b1d;
}

.admin-page .page-container {
  width: 100%;
  flex-grow: 1;
}

.admin-page.logged-in .page-container {
  padding: 100px 2% 50px;
}

.admin-page .message {
  position: absolute;
  top: 20px;
  background: var(--yellow);
  padding: 15px;
  color: #000000;
  font-weight: 500;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}

.admin-page.config .page-container {
  padding: 0;
}

.admin-page .page h2 {
  font-size: 24px;
  margin: 0;
}

.admin-page .block {
  border: 2px solid #333;
  padding: 25px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  border-radius: 8px;
  margin-bottom: 20px;
}

.admin-page ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.admin-page .animation-link,
.admin-page .admin-link {
  min-width: 200px;
  flex: 1 1 0;
  height: 200px;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #151515;
  cursor: pointer;
  transform: scale(1);
  box-shadow: 0 0 4px transparent;
  transition: all 0.5s;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  position: relative;
  /* max-width: 50%; */
}

.admin-page .admin-link {
  padding: 0;
}

.admin-page .admin-link li {
  padding: 0;
  width: 100%;
  height: 100%;
  transition: none;
  display: flex;
  align-items: flex-end;
  padding: 20px;
}

.admin-page li p {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  position: relative;
}

.block .block__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8));
  z-index: 0;
}

.admin-page .animation-link:hover,
.admin-page .admin-link:hover {
  transform: scale(1.05);
  border-color: var(--yellow);
}

.admin-page .admin-link:hover p {
  color: #ffffff;
}
